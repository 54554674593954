<template>
  <div class="view-container">
    <div class="card question-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Вопросы</div>
        <div class="tools">
          <router-link to="/test/question/edit" class="btn btn-accent"
            >Добавить</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <question-table />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionTable from "../../feature/testing/tables/QuestionTable.vue";
export default {
  components: { QuestionTable },
  name: "question-page",
};
</script>

<style>
</style>